import React from "react";
import { IconButton } from "@fluentui/react";
import { autobind } from "@utils/Decorators";
import { ClipBoard } from "@utils/General/ClipBoard";

import styles from "./CopyableLabel.module.css";

interface ICopyableLabelProps {
  text: string;
  className?: string;
  buttonAriaLabel: string;
  tabIndex?: number;
}

export class CopyableLabel extends React.Component<ICopyableLabelProps> {

  @autobind
  private copyText() {
    ClipBoard.copy(this.props.text);
  }

  public render() {
    return (
      <div className={`${styles.container} ${this.props.className ?? ""}`}>
        <div className={styles.wrapper}>
          <div className={styles.text}>{this.props.text}</div>
          <IconButton
            aria-label="Copy"
            iconProps={{ iconName: "Copy", className: styles["copy-icon"] }}
            className={styles["copy-button"]} onClick={this.copyText}
            ariaLabel={this.props.buttonAriaLabel}
            tabIndex={this.props.tabIndex ?? 0}
          />
        </div>
      </div>
    )
  }
}