import moment, { Moment } from "moment";
import { DateParseError } from "@errors/DateParseError";

export interface IProduct {
  repository: string;
  regHash: string;
  name: string;
  publisher: string;
  shortDescription: string;
  imagePath: string
  imageAltText: string;
  lastModifiedDate: string | null;
  sizeInBytes: number | null;
  architectures: string[] | null;
  categories: string[] | null;
  artifact: string[] | null;
  operatingSystems: string[] | null;
}

export class Product {
  public readonly repository: string;
  public readonly regHash: string;
  public readonly name: string;
  public readonly publisher: string;
  public readonly shortDescription: string;
  public readonly imagePath: string
  public readonly imageAltText: string;
  public readonly lastModifiedDate?: Moment;
  public readonly sizeInBytes?: number;
  public readonly architectures?: string[]
  public readonly categories?: string[]
  public readonly artifact?: string[]
  public readonly operatingSystems?: string[];

  protected constructor(productData: IProduct) {
    this.name = productData.name;
    this.repository = productData.repository;
    this.regHash = productData.regHash;
    this.publisher = productData.publisher;
    this.shortDescription = productData.shortDescription;
    this.imagePath = productData.imagePath;
    this.imageAltText = productData.imageAltText;
    this.lastModifiedDate = productData.lastModifiedDate ? moment(productData.lastModifiedDate) : undefined;
    this.sizeInBytes = productData.sizeInBytes ?? undefined;
    this.architectures = (productData.architectures && productData.architectures.length !== 0 ) ? productData.architectures : undefined;
    this.categories = (productData.categories && productData.categories.length !== 0 ) ? productData.categories : undefined;
    this.artifact = (productData.artifact && productData.artifact.length !== 0 ) ? productData.artifact : undefined;
    this.operatingSystems = (productData.operatingSystems && productData.operatingSystems.length !== 0 ) ? productData.operatingSystems : undefined;

    if (this.lastModifiedDate && !this.lastModifiedDate.isValid()) {
      throw new DateParseError(productData.lastModifiedDate!);
    }
  }

  public static fromJsonData(productData: IProduct) {
    return new Product(productData);
  }
}