import { AppContext } from '@utils/AppContext';

import React, { useContext } from "react";
import { LinkButton } from "@components/LinkButton";
import { Product } from "@models/Product";
import { ScreenSizeBreakpoint } from "@utils/MWF/ScreenSizeBreakpoints";
import { GridLayout } from "../GridLayout";
import { useHistory } from "react-router";

import cardStyles from "./ProductCard.module.css";
import { ProductSubRoute } from '@components/Pages/Product/ProductSubRoute';
import { AppRoute } from '@components/Routing/AppRoute';

interface IProductCardProps {
  product: Product;
}

const MICROSOFT_ONLY_CATEGORY = "Microsoft Only";

export const ProductCard: React.FC<IProductCardProps> = (props) => {

  const history = useHistory();
  const ctx = useContext(AppContext);
  const productRoute = AppRoute.getRoute(
    AppRoute.Artifact,
    ctx.locale,
    `/${props.product.regHash}/${props.product.repository}/${ProductSubRoute.Tags}`
  );

  function gotoProductPageWithLink(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    /* 
    Prevent default is used here to keep the href used in LinkButton from being activated.
    Without this line, the href would cause the page to be reloaded when the button is clicked, which we don't want.
    However, the href still needs to be included to make the link accessible to web crawlers and to open page in new tab.
    */
    event.preventDefault();
  }

  function gotoProductPage() {
    history.push(productRoute);
  }

  function render() {

    const cardBodyClasses = ["card material-card depth-8 depth-16-hover pb-3", cardStyles.container];
    const hasDescription = !!props.product.shortDescription;
    const description = hasDescription ? props.product.shortDescription : ctx.languagePack.catalog_page.product_card.no_description;
    const hasRootArtifact =  props.product.artifact && props.product.artifact.length > 0;
    const rootArt = props.product.artifact ? props.product.artifact.toString() : "";
    return (
      <div 
        className={cardBodyClasses.join(" ")}
        onClick={gotoProductPage}
      >
        <div className={` ${cardStyles.header}`}>
          {/* Icon */}
          <picture style={{ height: "36px", width: "36px" }}>
            <img src={props.product.imagePath} className={`${cardStyles.image} img-fluid`} alt={props.product.imageAltText} />
          </picture>

          {/* Publisher, title, date */}
          <div className={`${cardStyles["hide-overflow"]} ${cardStyles.info}`}>
            <section>{props.product.publisher}</section>
            <h3 className={`mw-fit-content ${cardStyles.title}`}>{props.product.name}</h3>
            {props.product.lastModifiedDate && (
                <section className="mb-1">Last Published: {props.product.lastModifiedDate.format('L')}</section>
            )}
            {hasRootArtifact && (<section className="mb-1">{rootArt}</section>)}
          </div>  
        </div>

        {/* Categories */}
        <div className="card-header">
          {props.product.categories !== undefined && (
            <>
              <section className={cardStyles["arch-list"]}>
                {props.product.categories?.map(category => (
                  <span key={category} className={`${(category.includes(MICROSOFT_ONLY_CATEGORY)) ? cardStyles["category-bubble-internal"] : cardStyles["category-bubble"]} mr-2 mb-1`} title={(category.includes(MICROSOFT_ONLY_CATEGORY)) ? ctx.languagePack.catalog_page.banner_message.unlisted_title : undefined} >{category}</span>
                ))}
              </section>
            </>
          )}
        </div>

        {/* Description */}
        <div className={`card-body ${cardStyles.description} pb-3`}>
          <p className={`${cardStyles.description} ${hasDescription ? "" : cardStyles["no-description"]}`}>{description}</p>
        </div>

        {/* Learn More */}
        <div className="card-footer pt-4">
          <div className="link-group">
            <LinkButton
              href={productRoute}
              aria-label={ctx.languagePack.catalog_page.product_card.learn_more_aria_label.replace("{0}", props.product.name)}
              className={ cardStyles["call-to-action"]}
              onClick={(event) => gotoProductPageWithLink(event)}
            >
              <span>{ctx.languagePack.catalog_page.product_card.learn_more}</span>
            </LinkButton>
          </div>
        </div>
      </div>
    )
  }

  return render();
}

interface IProductCardLayoutProps {
  className?: string;
}

export class ProductCardLayout extends React.Component<IProductCardLayoutProps> {

  private renderChildren() {
    if (this.props.children instanceof Array) {
      let children = this.props.children.map((node, nodeIndex) => {
        return (
          <GridLayout.Column
            key={`col_${nodeIndex}`}
            classes={["mb-4"]}
          >
            {node}
          </GridLayout.Column>
        )
      });
      return (
        <GridLayout.Row
          columnSettings={[
            ['default', 1],
            [ScreenSizeBreakpoint.sm, 2],
            [ScreenSizeBreakpoint.md, 3],
            [ScreenSizeBreakpoint.lg, 4],
            [ScreenSizeBreakpoint.xl, 5]
          ]}
        >
          {children}
        </GridLayout.Row>
      );
    } else {
      return (
        <GridLayout.Row
          columnSettings={[
            ['default', 1],
            [ScreenSizeBreakpoint.sm, 2],
            [ScreenSizeBreakpoint.md, 3],
            [ScreenSizeBreakpoint.lg, 4],
            [ScreenSizeBreakpoint.xl, 5]
          ]}
        >
          <GridLayout.Column classes={["mb-5 mb-xl-0"]} >
            {this.props.children}
          </GridLayout.Column>
        </GridLayout.Row>
      )
    }
  }

  render() {
    return (
      <GridLayout className={this.props.className}>
        {this.renderChildren()}
      </GridLayout>
    )
  }
}