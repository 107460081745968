import { AppContext } from "@utils/AppContext";
import { CopyableLabel } from "@components/CopyableLabel";
import { Input } from "@components/MWF/FormComponents/Input";
import { ITableColumn, Table } from "@components/MWF/Table";
import { Tag } from "@models/Tag";
import { autobind } from "@utils/Decorators";
import { Filter } from "@utils/Filter";
import React from "react";
import { Checkbox } from "@components/MWF/FormComponents/Checkbox";
import styles from "./TagTable.module.css";
import { TagTabRoute } from "../ProductSubRoute";
import { SbomDownloadButton } from "../SbomDownloadButton";

interface ITagTableProps {
  tags: Tag[];
  repo: string;
  supportedTags: string[];
  setSelectedTag: (tag: Tag) => void;
}

interface ITagTableState {
  filterValue: string;
  showOnlySupported: boolean;
  showSbomWindow: boolean;
  showSbomPkgWindow: boolean;
  sbomPkgWindowDigest: string | undefined;
  sbomWindowDigest: string | undefined;
  selectedTagTab?: TagTabRoute;
}

export class TagTable extends React.Component<ITagTableProps, ITagTableState> {
  public static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  constructor(props: ITagTableProps) {
    super(props);
    this.state = {
      filterValue: "",
      showOnlySupported: true,
      showSbomWindow: false,
      showSbomPkgWindow: false,
      sbomWindowDigest: undefined,
      sbomPkgWindowDigest: undefined,
    };
  }

  private getArtifactTypeString(
    artifactType: string,
    manifestType: string
  ): string {
    var str = " -- ";
    if (artifactType == "" && manifestType != undefined) {
      if (manifestType.includes("oci") && manifestType.includes("artifact")) {
        str = "OCI Artifact";
      } else if (manifestType.includes("oci.image.index")) {
        str = "OCI Image Index";
      } else if (manifestType.includes("manifest.list")) {
        str = "Manifest List";
      } else {
        str = artifactType;
      }
    } else if (artifactType == undefined) {
      str = " -- ";
    } else if (artifactType.includes("docker.container.image")) {
      str = "Docker Image";
    } else if (artifactType.includes("spdx")) {
      str = "SPDX SBOM";
    } else {
      str = artifactType;
    }

    return str;
  }

  private get columns(): ITableColumn<Tag>[] {
    const columnHeaderNames =
      this.context.languagePack.product_page.tag_table.headers;
    return [
      {
        field: "name",
        headerName: columnHeaderNames.name,
        cellRenderer: (params) => (
          <span className="font-weight-bold">{params.data.name}</span>
        ),
        sortFunction: "string",
      },
      {
        field: "artifactType",
        headerName: columnHeaderNames.artifactType,
        sortFunction: "string",
        cellRenderer: (params) => (
          <span title={params.data.artifactType}>
            {this.getArtifactTypeString(
              params.data.artifactType,
              params.data.manifestType
            )}
          </span>
        ),
      },
      {
        field: "digest",
        headerName: columnHeaderNames.digest,
        sortFunction: "string",
        valueFormatter: (params) => `${params.originalValue ?? "--"}`,
      },
      {
        field: "lastModifiedDate",
        headerName: this.context.languagePack.product_page.last_pushed,
        valueFormatter: (params) =>
          `${params.data.lastModifiedDate?.format("L") ?? "Unknown"}`,
        sortFunction: "date",
      },
    ];
  }

  private renderLabel(title: string, text: string) {
    return text ? (
      <div>
        <label className="font-weight-semibold mr-1">{title}: </label>
        {text}
      </div>
    ) : null;
  }

  private getAnnotationValue(tag: Tag, key: string): string {
    var cnt = "";
    if (tag.annotations && tag.annotations.get(key) != undefined) {
      cnt = tag.annotations.get(key)!;
    }
    return cnt;
  }

  private renderSbomHeaderInfo(tag: Tag): JSX.Element {
    var name = this.getAnnotationValue(tag, "org.spdx.name");
    var namespace = this.getAnnotationValue(tag, "org.spdx.namespace");
    var version = this.getAnnotationValue(tag, "org.spdx.version");

    return (
      <div>
        {this.renderLabel("Name", name)}
        {this.renderLabel("Namespace", namespace)}
        <label className="font-weight-semibold mr-1">{"Version"}: </label>
        {version}
        <SbomDownloadButton tag={tag} />
      </div>
    );
  }

  @autobind
  private renderTagTableCollapse(tag: Tag, open: boolean) {
    var isSbom = tag.artifactType == "application/spdx+json";
    var isWinSbom = tag.regHash == "ssc";

    return (
      <div>
        {!isWinSbom && (
          <CopyableLabel
            buttonAriaLabel={`Copy pull command for ${this.props.repo}. Tagged as ${tag.name}`}
            className="my-3 my-md-4"
            text={`${isSbom ? `oras` : `docker`} pull mcr.microsoft.com/${
              this.props.repo
            }:${tag.name}   `}
          />
        )}
        {isSbom && <div>{this.renderSbomHeaderInfo(tag)}</div>}
      </div>
    );
  }

  @autobind
  private onFilterChanged(newValue: string) {
    this.setState({
      filterValue: newValue,
    });
  }

  @autobind
  private onSupportedTagsChanged(checked: boolean) {
    this.setState({
      showOnlySupported: checked,
    });
  }

  private annotationsContain(tag: Tag, filterLowerCase: string) {
    let result = false;
    if (tag != undefined && tag.annotations != undefined) {
      for (let annotation of tag.annotations.keys()) {
        if (
          tag.annotations
            .get(annotation)
            ?.toLowerCase()
            .includes(filterLowerCase)
        ) {
          result = true;
          break;
        }
      }
    }
    return result;
  }

  public getFilterResult(filterValue: string, tag: Tag) {
    var result = false;

    if (tag != null && filterValue != null) {
      result = true;

      let filterLowerCase = filterValue?.toLowerCase();

      if (filterValue.includes(",")) {
        filterLowerCase.split(",").forEach((filter) => {
          if (filter != undefined && filter != "") {
            result =
              (result && tag.name.toLowerCase().includes(filter)) ||
              tag.repository.toLowerCase().includes(filter) ||
              tag.artifactType.toLowerCase().includes(filter) ||
              tag.digest.toLowerCase().includes(filter) ||
              !!tag.architecture?.toLowerCase().includes(filter) ||
              !!tag.operatingSystem?.toLowerCase().includes(filter) ||
              this.annotationsContain(tag, filter);
          }
        });
      } else {
        result =
          tag.name.toLowerCase().includes(filterLowerCase) ||
          tag.repository.toLowerCase().includes(filterLowerCase) ||
          tag.artifactType.toLowerCase().includes(filterLowerCase) ||
          tag.digest.toLowerCase().includes(filterLowerCase) ||
          !!tag.architecture?.toLowerCase().includes(filterLowerCase) ||
          !!tag.operatingSystem?.toLowerCase().includes(filterLowerCase) ||
          this.annotationsContain(tag, filterLowerCase);
      }
    }
    return result;
  }

  public render() {
    let filter = new Filter<Tag>();
    let filterValue = this.state.filterValue;
    let filterSupportedTags =
      this.state.showOnlySupported && this.props.supportedTags.length > 0;
    if (filterSupportedTags)
      filter.addRule((tag) => this.props.supportedTags.includes(tag.name));

    filter.addRule((tag) => {
      return this.getFilterResult(filterValue, tag);
    });

    return (
      <>
        <div className={`${styles["filter-header"]}`}>
          <Input
            onChange={this.onFilterChanged}
            placeholder={
              this.context.languagePack.product_page.tag_table
                .filter_placeholder
            }
            labelText={
              this.context.languagePack.product_page.tag_table.filter_label
            }
            ariaLabel={
              this.context.languagePack.product_page.tag_table.filter_aria_label
            }
            inlineWidth="370px"
            inline
          />
          <div className={`${styles["supported-tags-box"]}`}>
            {this.props.supportedTags.length > 0 && (
              <Checkbox
                onChange={this.onSupportedTagsChanged}
                label={
                  this.context.languagePack.product_page.tag_table
                    .supported_tags_label
                }
                defaultChecked
                inline
              />
            )}
          </div>
        </div>
        <Table
          items={this.props.tags}
          columns={this.columns}
          defaultSort={["lastModifiedDate", "date"]}
          small
          itemsPerPage={25}
          responsive
          renderCollapseContent={this.renderTagTableCollapse}
          filter={filter}
        />
      </>
    );
  }
}